import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Container } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Add } from '@material-ui/icons';

import { Subheader, TabPanel } from 'components';
import LotteryList from 'features/lotteries/LotteryList';
import LotteryDrawList from 'features/lotteryDraws/LotteryDrawList';
import { useNavigation } from 'hooks';

const CREATE_BUTTON_CONFIG = [
	{
		url: 'lotteries',
		title: 'Lottery'
	},
	{
		url: 'lottery_draws',
		title: 'Draw'
	}
];

const Lottery = ({ ...props }) => {
	const navigate = useNavigate();
	const [tabValue, setTabValue] = useState(0);
	const [createConfig, setCreateConfig] = useState(CREATE_BUTTON_CONFIG[0]);

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		setCreateConfig(CREATE_BUTTON_CONFIG[newValue]);
	};

	const { handleAddClick } = useNavigation({
		history,
		url: `/${createConfig.url}`
	});

	const createButton = useMemo(
		() => (
			<Button
				variant="contained"
				startIcon={<Add />}
				onClick={handleAddClick}
				color="primary"
			>
				{createConfig.title}
			</Button>
		),
		[createConfig.url]
	);

	return (
		<>
			<Subheader title="Lottery" buttons={[createButton]} />
			<Container maxWidth="xl">
				<AppBar
					position="static"
					style={{ background: '#FFF', color: '#000' }}
				></AppBar>
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					aria-label="LotteryDraw Tabs"
				>
					<Tab label="Lotteries" />
					<Tab label="Lottery Draws" />
				</Tabs>
			</Container>
			<Container maxWidth="xl" className="custom-Mul-Container">
				<>
					<TabPanel value={tabValue} index={0}>
						<LotteryList title={'Lotteries'} />
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<LotteryDrawList title={'Lottery Draws'} />
					</TabPanel>
				</>
			</Container>
		</>
	);
};

export default Lottery;
