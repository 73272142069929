import React, { useMemo } from 'react';
import { Grid, Box } from '@material-ui/core';
import {
	Autosuggest,
	TextInput,
	ImageUploader,
	VideoUploader
} from 'components';
import { TRAINING_PLAN_TRIVIA_MEDIA_TYPES } from 'lib/constants/trainingPlan';
import { compact } from 'lodash';

const TriviaQuestionForm = ({
	question,
	handleChange,
	handleUploadFile,
	handleDelete,
	memoryFile,
	handleMediaChange,
	triviaMedia,
	handleAnswerChange,
	handleMediaDelete
}) => {
	// Check if media type is 'text' to hide answer media fields
	const isTextMediaType = question.media_type === 'text';
	const isAnswersTextMediaType = question.answers_media_type === 'text';

	const isMediaTypeSelected = !!question.media_type;
	const isAnswersMediaTypeSelected = !!question.answers_media_type;

	const correctAnswer = useMemo(() => {
		return compact(
			triviaMedia?.map((answer, index) => {
				if (answer.id === question.correct_answer_id) {
					return index + 1;
				}
			})
		);
	}, [triviaMedia]);

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<TextInput
					rows={4}
					multiline
					value={question.question || ''}
					label="Trivia Question"
					name="question"
					placeholder="Enter Trivia Question"
					handleChange={handleChange}
				/>
			</Grid>

			<Grid item xs={12}>
				<Autosuggest
					options={TRAINING_PLAN_TRIVIA_MEDIA_TYPES.map((type) => ({
						label: type.label,
						value: type.value
					}))}
					value={question.media_type || ''}
					label="Question Media Type"
					name="media_type"
					placeholder="Select Media Type"
					handleChange={handleChange}
				/>
			</Grid>

			{isMediaTypeSelected && (
				<>
					{!isTextMediaType && (
						<Grid item xs={12}>
							{question.media_type === 'image' ? (
								<ImageUploader
									label="Upload Image"
									imageUrl={question.media_url || memoryFile?.media_url?.url}
									handleUpload={(file) => handleUploadFile(file, 'media_url')}
									handleDelete={() => handleDelete('media_url')}
								/>
							) : (
								<VideoUploader
									label="Upload Video"
									videoUrl={question.media_url || memoryFile?.media_url?.url}
									handleUpload={(file) => handleUploadFile(file, 'media_url')}
									handleDelete={() => handleDelete('media_url')}
								/>
							)}
						</Grid>
					)}
				</>
			)}

			<Grid item xs={12}>
				<Autosuggest
					options={TRAINING_PLAN_TRIVIA_MEDIA_TYPES.map((type) => ({
						label: type.label,
						value: type.value
					}))}
					value={question.answers_media_type || ''}
					label="Answers Media Type"
					name="answers_media_type"
					placeholder="Select Media Type"
					handleChange={handleChange}
				/>
			</Grid>
			{isAnswersMediaTypeSelected && (
				<>
					<Grid item xs={12}>
						<Autosuggest
							options={[1, 2, 3, 4].map((num) => ({
								label: `${num}`,
								value: num
							}))}
							value={
								question.correct_answer || (correctAnswer && correctAnswer[0])
							}
							label="Correct Answer"
							name="correct_answer"
							handleChange={handleChange}
						/>
					</Grid>

					{triviaMedia?.map((answer, index) => (
						<Grid item xs={12} key={index}>
							{isAnswersTextMediaType && (
								<TextInput
									label={`Trivia Answer ${index + 1}`}
									name={`answer_${index}`}
									value={answer.answer || ''}
									handleChange={(e) => {
										handleAnswerChange(answer.id, e.target.value);
									}}
								/>
							)}
							{!isAnswersTextMediaType && (
								<Box py={2}>
									{question.answers_media_type === 'image' ? (
										<ImageUploader
											label={`Answer ${index + 1} Image`}
											imageUrl={answer.media_url || answer.url}
											handleUpload={(file) =>
												handleMediaChange(answer.id, file)
											}
											handleDelete={() => handleMediaDelete(answer.id, '')}
										/>
									) : (
										<VideoUploader
											label={`Answer ${index + 1} Video`}
											videoUrl={answer.media_url || answer.url}
											handleUpload={(file) =>
												handleMediaChange(answer.id, file)
											}
											handleDelete={() => handleMediaDelete(answer.id, '')}
										/>
									)}
								</Box>
							)}
						</Grid>
					))}
				</>
			)}
		</Grid>
	);
};

export default TriviaQuestionForm;
