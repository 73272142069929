import React from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { DisplayImage, DisplayText, LineItem } from 'components';
import EventMediaDisplay from '../common/event_videos';

const EventDetail = ({ event, reloadEvent }) => (
	<>
		<div>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<DisplayText label={'Event Description:'} text={event.description} />
				</Grid>
				<Grid item xs={4}>
					<DisplayText
						label={'Start Date:'}
						text={new Date(event.start_date).toLocaleDateString('en-GB')}
					/>
				</Grid>
				<Grid item xs={4}>
					<DisplayText
						label={'End Date:'}
						text={new Date(event.end_date)?.toLocaleDateString('en-GB')}
					/>
				</Grid>
				<Grid item xs={4}>
					<DisplayText
						label={'Type:'}
						text={
							event.event_type == 'video_upload'
								? 'Video Upload'
								: 'Image Upload'
						}
					/>
				</Grid>
				<Grid item xs={4}>
					<DisplayText
						label={'Assigned Partner:'}
						text={event?.partner?.name}
					/>
				</Grid>
				<Grid item xs={4}>
					<DisplayText
						label={'Published Event:'}
						text={event.published ? 'Yes' : 'No'}
					/>
				</Grid>
				<EventMediaDisplay
					id={event.id}
					eventVideos={event.event_videos || []}
					image={event.image_url}
					reloadEvent={reloadEvent}
					event={event}
				/>
			</Grid>
		</div>
	</>
);

export default EventDetail;
