import React, { useEffect, useState } from 'react';

import {
	Box,
	Button,
	Container,
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core';
import {
	Check,
	Close,
	CompareArrowsSharp,
	TramSharp
} from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { ImageUploader, Subheader, VideoUploader } from 'components';
import { useAlerts, useTriviaQuestions, useUploadFileInMemory } from 'hooks';
import TriviaQuestionForm from 'features/trainingPlan/trainingPlan/trivia/TriviaQuestionForm';
import { triviaQuestionValidation } from 'validations/triviaQuestion';
import { find, set } from 'lodash';
import { getUrlFromFile } from 'hooks/useUploadFileInMemory';

const TriviaQuestionEdit = () => {
	const navigate = useNavigate();
	const { id, training_plan_id } = useParams();
	const [triviaMedia, setTriviaMedia] = useState();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();
	const {
		handleChange,
		triviaQuestion,
		findTriviaQuestion,
		destroyTriviaQuestion,
		updateTriviaBlock,
		setTriviaQuestion
	} = useTriviaQuestions();
	const { showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const triviaAnswerWithMediaTypes = triviaMedia.map((media) => ({
			...media,
			media_type: triviaQuestion.answers_media_type
		}));

		await updateTriviaBlock({
			...triviaQuestion,
			trivia_answers: triviaAnswerWithMediaTypes,
			files: memoryFile
		}).finally(() => {
			navigate(`/training-plans/${training_plan_id}`);
		});
	};

	const handleDeleteQuestion = async (question) => {
		await destroyTriviaQuestion(question).finally(() => {
			navigate(`/training-plans/${training_plan_id}`);
		});
	};

	const handleUpload = async (file, type) => {
		await handleUploadFile(file, type);
	};

	const handleDelete = async (type) => {
		removeFile(type);
		setTriviaQuestion({ ...triviaQuestion, [type]: '' });
	};

	const handleMediaDelete = async (index) => {
		const updatedMedia = await triviaMedia.map((media) => {
			if (media.id === index) {
				return { ...media, file: '', url: '', media_url: '' };
			} else {
				return media;
			}
		});

		setTriviaMedia(updatedMedia);
	};

	const handleMediaChange = async (index, file) => {
		const url = await getUrlFromFile(file);
		const updatedMedia = await triviaMedia.map((media) => {
			if (media.id === index) {
				return { ...media, file: file, url: url };
			} else {
				return media;
			}
		});

		setTriviaMedia(updatedMedia);
	};

	useEffect(() => {
		if (id) findTriviaQuestion(id);
	}, [id]);

	useEffect(() => {
		if (triviaQuestion) {
			setTriviaMedia(triviaQuestion.trivia_answers);
		}
	}, [triviaQuestion]);

	const handleAnswerChange = async (index, value) => {
		const updatedAnswers = triviaMedia.map((item) => {
			if (item.id === index) {
				return { ...item, answer: value };
			} else {
				return item;
			}
		});

		setTriviaMedia(updatedAnswers);
	};

	const handleOnChange = async (e) => {
		const { name, value } = e.target;

		if (name === 'answers_media_type') {
			const updatedMedia = triviaMedia.map((media) => {
				return { ...media, media_type: value, media_url: '', answer: '' };
			});

			setTriviaMedia(updatedMedia);
			setTriviaQuestion({
				...triviaQuestion,
				trivia_answers: updatedMedia,
				answers_media_type: value
			});
		} else {
			handleChange(e);
		}
	};

	const classes = useStyles();

	return (
		<div>
			<Subheader
				title="Update Trivia Question"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<TriviaQuestionForm
					question={triviaQuestion}
					handleChange={handleOnChange}
					handleDelete={handleDelete}
					triviaMedia={triviaMedia}
					handleAnswerChange={handleAnswerChange}
					handleMediaDelete={handleMediaDelete}
					handleMediaChange={handleMediaChange}
					handleUploadFile={handleUpload}
					memoryFile={memoryFile}
				/>

				<div className={classes.container}>
					<div item sm={6} md={6} x={6}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</div>
					<div item sm={6} md={6} x={6} className={classes.item}>
						<Button
							startIcon={<Close />}
							color="secondary"
							variant="outlined"
							onClick={() => handleDeleteQuestion(triviaQuestion)}
						>
							Delete Question
						</Button>
					</div>
				</div>
			</Container>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '20px'
	},
	item: {
		alignSelf: 'flex-start'
	}
}));

export default TriviaQuestionEdit;
