import React, { useEffect, useState } from 'react';
import {
	usePartners,
	useAlerts,
	useNavigation,
	useMenu,
	useSelected
} from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { SortableTableHeader, Subheader, Placeholder } from 'components';
import Pagination from '@material-ui/lab/Pagination';
import {
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import EventToolbar from 'features/events/EventToolbar';
import SearchForm from 'features/events/SearchForm';
import PartnerItem from './PartnerItem';

const TABLE_HEADERS = [
	{
		value: 'partner_name',
		label: 'Partner Name',
		sortable: true,
		hidden: false
	},
	{
		value: 'partner_login_email',
		label: 'Partner Email',
		sortable: false,
		hidden: false
	}
];

const PartnersList = ({ ...props }) => {
	const navigate = useNavigate();
	const {
		isLoading,
		isEmpty,
		partners,
		findPartners,
		deletePartner,
		paginate,
		query,
		page,
		numPages,
		reloadPartners,
		sortKey,
		sortDirection,
		handleSort
	} = usePartners({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/partners'
	});

	const { selected, selectedIds, handleSelect, handleClear, totalSelected } =
		useSelected();

	const { showAlertSuccess, showAlertError } = useAlerts();

	useEffect(() => {
		findPartners(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	return (
		<>
			<Container maxWidth="xl" className="custom-Mul-Container">
				<>
					<Paper>
						<SearchForm
							isLoading={isLoading}
							query={query}
							handleSearch={findPartners}
						/>
						{!isEmpty ? (
							<TableContainer component={Paper} elevation={0}>
								<Table>
									<TableHead>
										<TableRow>
											{TABLE_HEADERS.filter((h) => !h.hidden).map(
												(header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`events.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												)
											)}
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{partners.map((partner, idx) => (
											<PartnerItem
												key={idx}
												partner={partner}
												isSelectable={false}
												selectedIds={selectedIds}
												handleSelect={handleSelect}
												handleClick={handleClick}
												handleEditClick={handleEditClick}
												deletePartner={deletePartner}
												reloadPartners={reloadPartners}
												showAlertSuccess={showAlertSuccess}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Placeholder
								icon={<Search />}
								title="No Partners"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
				<EventToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

export default PartnersList;
