import React, { useEffect } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextInput, VideoUploader } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useAlerts,
	useEvents,
	useEventVideos,
	usePartners,
	useUploadFileInMemory
} from 'hooks';
import { validateEventVideo } from 'validations/event_video';

const EventVideosForm = ({ handleEventVideoCallback }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { event, findEvent } = useEvents({ id });
	const { findPartners, query, page, sortKey, sortDirection } = usePartners({});
	const {
		event_video,
		handleChange: handleChangeVideo,
		clearResource: clearResourceVideo,
		createEventVideo
	} = useEventVideos({});
	const { showAlertSuccess, showAlertError } = useAlerts();
	const [showEventVideos, setShowEventVideos] = React.useState(false);

	const { memoryFile, removeFile, handleUploadFile, clearAllFiles } =
		useUploadFileInMemory();

	const handleSaveVideo = async () => {
		const event_data = {
			event_id: event.id,
			title: event_video.title,
			video_url: event_video.cloudinary_url || memoryFile?.video_url?.url || ''
		};

		let valid = validateEventVideo(event_data, memoryFile);

		if (valid.isValid) {
			await createEventVideo(event_data, memoryFile)
				.then(() => {
					showAlertSuccess('Event video has been added');
					setShowEventVideos(false);
					handleEventVideoCallback();
					clearResourceVideo();
					clearAllFiles();
				})
				.catch((err) => {
					showAlertError('Failed to add event video');
					showAlertError(err.message);
				});
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleVideoSelect = (video) => {
		const maxSize = 350 * 1024 * 1024;
		if (video && video.size > maxSize) {
			showAlertError('For large size video, use cloudinary url');
			return;
		}

		handleUploadFile(video, 'video_url');
	};

	useEffect(() => {
		if (id) findEvent(id);
	}, [id]);

	useEffect(() => {
		findPartners(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	const handleDeleteFile = async (type) => {
		removeFile();
	};

	return (
		<>
			<Grid item xs={12}>
				<TextInput
					name="title"
					label="Event Video Title"
					fullWidth
					value={event_video.title || ''}
					onChange={handleChangeVideo}
				/>

				<Box paddingTop={3}>
					<VideoUploader
						label="Select Video File"
						videoUrl={memoryFile?.video_url?.url}
						handleUpload={handleVideoSelect}
						handleDelete={() => handleDeleteFile('video_url')}
					/>
				</Box>

				<Box paddingTop={1} paddingBottom={2}>
					<TextInput
						name="cloudinary_url"
						label="Cloudinary Video URL"
						fullWidth
						value={event_video.cloudinary_url || ''}
						onChange={handleChangeVideo}
					/>
				</Box>
			</Grid>

			<Grid item xs={12}>
				<Button color="primary" variant="contained" onClick={handleSaveVideo}>
					Upload Event Video
				</Button>
			</Grid>
		</>
	);
};

export default EventVideosForm;
