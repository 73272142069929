import {
	Box,
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Placeholder, SortableTableHeader, Subheader } from 'components';
import LotteryItem from 'features/lotteries/LotteryItem';
import LotteryToolbar from 'features/lotteries/LotteryToolbar';
import SearchForm from 'features/lotteries/SearchForm';
import { useAlerts, useLotteries, useNavigation, useSelected } from 'hooks';

const TABLE_HEADERS = [
	{
		value: 'month',
		label: 'Month',
		sortable: true,
		hidden: false
	},

	{
		value: 'year',
		label: 'Year',
		sortable: true,
		hidden: false
	},

	{
		value: 'point_required',
		label: 'Points Required',
		sortable: true,
		hidden: false
	},
	{
		value: 'prize_description',
		label: 'Prize Description',
		sortable: true,
		hidden: false
	},
	{
		value: 'winner_id',
		label: 'Winner',
		sortable: true,
		hidden: false
	},

	{
		value: 'status',
		label: 'Status',
		sortable: true,
		hidden: false
	},
	{
		value: 'created_at',
		label: 'Created On',
		sortable: true,
		hidden: false
	}
];

const LotteryList = ({ ...props }) => {
	const navigate = useNavigate();
	const status = 'active';

	const {
		isLoading,
		isEmpty,
		lotteries,
		findLotteries,
		deleteLottery,
		paginate,
		query,
		page,
		numPages,
		reloadLotteries,
		sortKey,
		sortDirection,
		handleSort
	} = useLotteries({});

	const searchParams = React.useMemo(
		() => ({
			'lotteries.status': status
		}),
		[status]
	);

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/lotteries'
	});

	const handleSelectWinnerClick = (e) => {
		const id = e.id;
		navigate(`/lotteries/${id}/select-winner`);
	};

	const { selectedIds, handleSelect, selected, handleClear, totalSelected } =
		useSelected();

	const { showAlertSuccess } = useAlerts();

	const handleDeleteClick = async (lottery) => {
		await deleteLottery(lottery);
		showAlertSuccess('Lottery has been deleted');
		reloadLotteries();
	};

	React.useEffect(() => {
		findLotteries(
			{
				...searchParams,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [searchParams, page, sortKey, sortDirection]);

	return (
		<Container maxWidth="lg" className="custom-Mul-Container">
			<>
				<Paper>
					<SearchForm
						isLoading={isLoading}
						query={query}
						handleSearch={findLotteries}
					/>
					{!isEmpty ? (
						<TableContainer component={Paper} elevation={0}>
							<Table>
								<TableHead>
									<TableRow>
										{TABLE_HEADERS.filter((h) => !h.hidden).map(
											(header, idx) => (
												<SortableTableHeader
													key={idx}
													handleSort={handleSort}
													value={`lotteries.${header.value}`}
													sortable={header.sortable}
													sortKey={sortKey}
													sortDirection={sortDirection}
												>
													{header.label}
												</SortableTableHeader>
											)
										)}
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{lotteries.map((lottery, idx) => (
										<LotteryItem
											key={idx}
											lottery={lottery}
											isSelectable={false}
											selectedIds={selectedIds}
											handleSelect={handleSelect}
											handleClick={handleClick}
											handleSelectWinnerClick={handleSelectWinnerClick}
											handleDeleteClick={handleDeleteClick}
											handleEditClick={handleEditClick}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Placeholder
							icon={<Search />}
							title="No lotteries"
							subtitle="Please try another search"
						/>
					)}
				</Paper>
				{!isEmpty && (
					<Box my={1}>
						<Pagination
							onChange={(ev, page) => paginate(page)}
							color="primary"
							size="small"
							shape="rounded"
							page={page}
							count={numPages}
						/>
					</Box>
				)}
			</>
			<LotteryToolbar
				selected={selected}
				selectedIds={selectedIds}
				handleClear={handleClear}
				totalSelected={totalSelected}
			/>
		</Container>
	);
};

export default LotteryList;
