import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Container } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Add } from '@material-ui/icons';

import { Subheader, TabPanel } from 'components';
import AchievementList from 'features/achievements/AchievementList';
import ReferralList from 'features/referrals/ReferralList';
import RewardList from 'features/rewards/RewardList';
import { useNavigation } from 'hooks';

const CREATE_BUTTON_CONFIG = [
	{
		url: 'achievements',
		title: 'Achievement'
	},
	{
		url: 'rewards',
		title: 'Rewards'
	},
	{
		url: 'referrals',
		title: 'Referrals'
	}
];

const Reward = ({ ...props }) => {
	const navigate = useNavigate();
	const [tabValue, setTabValue] = useState(0);
	const [createConfig, setCreateConfig] = useState(CREATE_BUTTON_CONFIG[0]);

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		setCreateConfig(CREATE_BUTTON_CONFIG[newValue]);
	};

	const { handleAddClick } = useNavigation({
		history,
		url: `/${createConfig.url}`
	});

	const createButton = useMemo(() => {
		return createConfig.url !== 'referrals' ? (
			<Button
				variant="contained"
				startIcon={<Add />}
				onClick={handleAddClick}
				color="primary"
			>
				{createConfig.title}
			</Button>
		) : null;
	}, [createConfig.url]);

	return (
		<>
			<Subheader title="Rewards" buttons={[createButton]} />
			<Container maxWidth="xl">
				<AppBar
					position="static"
					style={{ background: '#FFF', color: '#000' }}
				></AppBar>
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					aria-label="Reward Tabs"
				>
					<Tab label="Achievements" />
					<Tab label="Rewards" />
					<Tab label="Referrals" />
				</Tabs>
			</Container>
			<Container maxWidth="xl" className="custom-Mul-Container">
				<>
					<TabPanel value={tabValue} index={0}>
						<AchievementList title={'Achievements'} />
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<RewardList title={'Rewards'} />
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						<ReferralList title={'Referrals'} />
					</TabPanel>
				</>
			</Container>
		</>
	);
};

export default Reward;
