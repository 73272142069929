import {
	Button,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow
} from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';

import React from 'react';

const EntryItem = ({
	entry,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const isSelected = selectedIds.includes(entry.id);

	console.log('entry', entry);

	return (
		<TableRow>
			<TableCell>
				<Button onClick={() => handleClick(entry)}>
					{entry.user.first_name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(entry)}>
					{entry.user.last_name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(entry)}>{entry.user.country}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(entry)}>{entry.user.age}</Button>
			</TableCell>
			{/* <TableCell> */}

			{/* </TableCell> */}
			<TableCell align="right">
				{entry.status === 'approved' && (
					<Button
						variant="contained"
						disabled
						style={{ backgroundColor: '#5a606f', color: 'white' }}
					>
						Shortlisted
					</Button>
				)}
				<IconButton onClick={(e) => handleClick(entry)}>
					<ArrowRight />
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default EntryItem;
