import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { Autosuggest, TextInput, SwitchInput, ImageUploader } from 'components';

import EventMediaDisplay from './common/event_videos';

const EventForm = ({
	event,
	partners,
	handleChange,
	memoryFile,
	reloadEvent,
	handleUploadFile,
	status,
	handleDelete,
	partnerId,
	handleUpload,
	handleDeleteFile
}) => (
	<>
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<TextInput
					name="name"
					label="Event Name"
					fullWidth
					value={event.name}
					onChange={handleChange}
					maxLength={30}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextInput
					name="description"
					label="Event Description"
					multiline
					fullWidth
					value={event.description}
					onChange={handleChange}
					maxLength={120}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextInput
					name="start_date"
					label="Start Date"
					type="date"
					fullWidth
					required
					value={event.start_date}
					onChange={handleChange}
					InputLabelProps={{ shrink: true }}
					min={new Date().toISOString().split('T')[0]}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextInput
					name="end_date"
					label="End Date"
					type="date"
					fullWidth
					value={event.end_date}
					required
					onChange={handleChange}
					disabled={!event.start_date}
					InputLabelProps={{ shrink: true }}
					min={event.start_date}
				/>
			</Grid>
			<Grid item xs={6}>
				{partners.length > 0 && (
					<Autosuggest
						options={partners.map((item) => ({
							label: item.name,
							value: item.id
						}))}
						label="Select Partner"
						name="partner_id"
						value={partnerId ? partnerId : event.partner_id}
						handleChange={handleChange}
						placeholder="Select a partner"
					/>
				)}
			</Grid>

			<Grid item xs={6}>
				<Autosuggest
					options={[{ label: 'Video upload', value: 'video_upload' }]}
					value={event.event_type || []}
					label="Event Type"
					name="event_type"
					placeholder="Select one"
					handleChange={handleChange}
				/>
			</Grid>

			<Grid item xs={6}>
				<Typography variant="body1" color="textSecondary" gutterBottom>
					Event Cover Image (3:2 Ratio)
				</Typography>

				{event.image_url ? null : (
					<ImageUploader
						label="Upload event image (2:3)"
						imageUrl={event.image_url || memoryFile?.image_url?.url}
						handleUpload={(file) =>
							status === 'edit'
								? handleUpload(file, 'image_url')
								: handleUploadFile(file, 'image_url')
						}
						handleDelete={() =>
							status === 'edit' && event.image_url
								? handleDelete('image_url')
								: handleDeleteFile('image_url')
						}
					/>
				)}
			</Grid>
			<Grid item xs={6}>
				<SwitchInput
					value={event.published ? true : false}
					label="Published"
					name="published"
					placeholder="Published"
					handleChange={handleChange}
				/>
			</Grid>

			{status === 'edit' && (
				<EventMediaDisplay
					id={event.id}
					eventVideos={event.event_videos || []}
					image={event.image_url}
					reloadEvent={reloadEvent}
				/>
			)}
		</Grid>
	</>
);

export default EventForm;
