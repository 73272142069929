import {
	Box,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Placeholder, SortableTableHeader } from 'components';
import ReferralItem from 'features/referrals/ReferralItem';
import ReferralToolbar from 'features/referrals/ReferralToolbar';
import SearchForm from 'features/referrals/SearchForm';
import { useAlerts, useNavigation, useReferrals, useSelected } from 'hooks';

const TABLE_HEADERS = [
	{
		value: 'referrer_id',
		label: 'Referrer',
		sortable: true,
		hidden: false
	},

	{
		value: 'new_user_id',
		label: 'New User',
		sortable: true,
		hidden: false
	},

	{
		value: 'point',
		label: 'Points',
		sortable: true,
		hidden: false
	},
	{
		value: 'status',
		label: 'Status',
		sortable: true,
		hidden: false
	},
	{
		value: 'created_at',
		label: 'Created On',
		sortable: true,
		hidden: false
	}
];

const ReferralList = ({ ...props }) => {
	const navigate = useNavigate();
	const status = 'accepted';

	const {
		isLoading,
		isEmpty,
		referrals,
		findReferrals,
		deleteReferral,
		paginate,
		query,
		page,
		numPages,
		reloadReferrals,
		sortKey,
		sortDirection,
		handleSort
	} = useReferrals({});

	const searchParams = React.useMemo(
		() => ({
			'referrals.status': status
		}),
		[status]
	);

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/referrals'
	});

	const { selectedIds, handleSelect, selected, handleClear, totalSelected } =
		useSelected();

	const { showAlertSuccess } = useAlerts();

	const handleDeleteClick = async (referral) => {
		await deleteReferral(referral);
		showAlertSuccess('Referral has been deleted');
		reloadReferrals();
	};

	React.useEffect(() => {
		findReferrals(
			{
				...searchParams,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [searchParams, page, sortKey, sortDirection]);

	return (
		<>
			<Container maxWidth="xl" className="custom-Mul-Container">
				<>
					<Paper>
						<SearchForm
							isLoading={isLoading}
							query={query}
							handleSearch={findReferrals}
						/>
						{!isEmpty ? (
							<TableContainer component={Paper} elevation={0}>
								<Table>
									<TableHead>
										<TableRow>
											{TABLE_HEADERS.filter((h) => !h.hidden).map(
												(header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`referrals.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												)
											)}
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{referrals.map((referral, idx) => (
											<ReferralItem
												key={idx}
												referral={referral}
												isSelectable={false}
												selectedIds={selectedIds}
												handleSelect={handleSelect}
												handleClick={handleClick}
												handleDeleteClick={handleDeleteClick}
												handleEditClick={handleEditClick}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Placeholder
								icon={<Search />}
								title="No referrals"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
				<ReferralToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

export default ReferralList;
